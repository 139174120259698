import { useRef, useEffect, useState } from "react";
const API_URL = 'https://notes-36r.pages.dev/';
interface InfoResponse {
  value: string;
}
const Note = ({ noteId }: { noteId: string}) => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const [noteVal, setNoteVal] = useState('');

  useEffect(() => {
    const getNote = async () => {
      const response = await fetch(`/get/${noteId.substring(1)}`);
      const noteInfo = (await response.json()) as any;
      console.log('Set note to ', noteInfo.value, noteInfo);
      setNoteVal(noteInfo.value);
    }
    getNote();
  }, [noteId]);

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <textarea ref={textarea} className="h-full w-full rounded-xl border-green-400 p-1 border-2 focus:outline-none focus:border-green-600 hover:border-green-600" readOnly value={noteVal}></textarea>
      </div>
    </>
  );
}

export default Note;