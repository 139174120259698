import { useRef, useEffect, useState } from "react";

const Textarea = () => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const [noteId, setNoteId] = useState();
  useEffect(() => {
    textarea.current?.focus();
  }, []);

  const submitNote = async () => {
    const response = await fetch('/put', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ text: textarea.current?.value })
    });
    const note: any = await response.json();
    setNoteId(note.id);
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://${window.location.hostname}/#${noteId}`);
  }
  return (
    <div className="flex items-center h-full">
      {noteId && (
        <div className="flex flex-1 flex-col justify-center align-middle">
          <input type="text" readOnly value={`https://${window.location.hostname}/#${noteId}`} className="p-1 border-2 border-green-400 focus:border-green-600 hover:border-green-600 w-full focus:shadow-outline focus:outline-none" />
          <button className="h-10 w-1/3 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-blue-600 rounded-lg focus:shadow-outline hover:bg-blue-800" onClick={copyToClipboard}>Copy</button>
        </div>
      )}
      {!noteId && (
        <div className="flex flex-col h-full w-full justify-center">
          <textarea ref={textarea} className="h-full w-full rounded-xl border-blue-400 p-1 border-2 focus:outline-none focus:border-blue-600"></textarea>
          <label className="inline-flex items-center justify-center">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span className="ml-2">One time use</span>
          </label>
          <button className="h-10 m-auto w-1/3 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-blue-600 rounded-lg focus:shadow-outline hover:bg-blue-800" onClick={submitNote}>Create Note</button>
        </div>
      )}
    </div>
  );
}

export default Textarea;