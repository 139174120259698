import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Textarea from './Textarea';
import Note from './Note';

function App() {
  const [noteId, setNoteId] = useState<string | null>(null);

  const onHashChange = () => {
    console.log('HAsh changed', window.location.hash);
    setNoteId(window.location.hash);
  };

  useEffect(() => {
    window.addEventListener("hashchange", onHashChange, false);
    onHashChange();
    return () => {
      window.removeEventListener("hashchange", onHashChange, false);
    }
  }, []);

  return (
    <div className="w-screen h-screen bg-blue-100 flex">
      <div className="p-6 m-auto bg-white rounded-xl shadow-md flex items-center align-middle w-2/4 h-2/5">
        <div className="flex-shrink-0">
          {/* <img className="h-12 w-12" src="/img/logo.svg" alt="ChitChat Logo"> */}
        </div>
        <div className="w-full h-full m-0 p-0">
          {!noteId && <Textarea />}
          {noteId && <Note noteId={noteId} />}
          {/* <div className="text-xl font-medium text-black">Enter</div>
          <p className="text-gray-500">You have a new message!</p> */}
        </div>
      </div>
    </div>
  );
}

export default App;
